import gql from "graphql-tag";
import { featuredProductFragment } from '../../@next/graphql/fragments'

export const homePageProductsQuery = gql`
  query HomePageProducts {
    shop {
      description
      name
    }
    categories(level: 0, first: 10) {
      edges {
        node {
          id
          name
          slug
          backgroundImage {
            url
          }
          metadata {
            key
            value
          }
        }
      }
    }
  }
`;

// export const getCollectionsQuery = gql`
//   query GetCollections($channel: String, $filter: CollectionFilterInput) {
//     collections(channel: $channel, first: 100, filter: $filter) {
//       edges {
//         node {
//           name
//           slug
//           backgroundImage {
//             url
//             alt
//           }
//           metadata {
//             key
//             value
//           }
//         }
//       }
//     }
//   }
// `;

export const getFeaturedProductsQuery = gql`
  query {
    collections(
      filter: { search: "featured-products" }
      channel: "default-channel"
      first: 3
    ) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
          }
          slug
          metadata {
            key
            value
          }
          products(first: 20) {
            edges {
              node {
                ...FeaturedProduct
                defaultVariant {
                  id
                }
                slug
                productType {
                  id
                  name
                }
                isAvailableForPurchase
                variants {
                  id
                  name
                  quantityAvailable
                  media {
                    url
                  }
                  attributes {
                    attribute {
                      name
                      id
                      unit
                    }
                    values {
                      name
                    }
                  }
                  pricing {
                    price {
                      gross {
                        amount
                        currency
                      }
                      net {
                        amount
                        currency
                      }
                    }
                    priceUndiscounted {
                      gross {
                        amount
                        currency
                      }
                      net {
                        amount
                        currency
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${featuredProductFragment}
`