import { NextPage } from "next";
import * as React from "react";

import { FeaturedProducts } from "@utils/ssr";

import { MetaWrapper } from "../../components";
import { HomePageProducts } from "./gqlTypes/HomePageProducts";
import Page from "./Page";
import Head from 'next/head'

import "./scss/index.module.scss";

export interface HomeViewProps {
  data: HomePageProducts & { featuredProducts: FeaturedProducts };
}

export const HomeView: NextPage<HomeViewProps> = ({
  data: { shop, featuredProducts, categories, collections },
}) => (
  <div className="home-page">
    <Head>
      <meta name="apple-itunes-app" content="app-id=1503178309" />
    </Head>
    <MetaWrapper
      meta={{
        description: shop?.description || "",
        title: shop.name || "",
      }}
    >
      <Page
        featuredProducts={featuredProducts}
        categories={categories}
        shop={shop}
        collections={collections}
      />
    </MetaWrapper>
  </div>
);
