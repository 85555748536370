/* eslint-disable @typescript-eslint/no-shadow */
import { GetStaticProps } from "next";

import {
  channelSlug,
  incrementalStaticRegenerationRevalidate,
} from "@temp/constants";
import { getSaleorApi } from "@utils/ssr";

import { homePageProductsQuery, HomeView, HomeViewProps, getFeaturedProductsQuery } from "../views/Home";
import { getCollectionsQuery } from "@temp/queries/queries";
import { HomePageProducts } from "../views/Home/gqlTypes/HomePageProducts";

export default HomeView;

export const getStaticProps: GetStaticProps<HomeViewProps> = async () => {
  
  const { apolloClient } = await getSaleorApi();
  const [data, collections, featuredProducts] = await Promise.all([
    apolloClient
      .query<HomePageProducts>({
        query: homePageProductsQuery,
        fetchPolicy: 'no-cache',
        variables: { channel: channelSlug },
      })
      .then(({ data }) => data),
    apolloClient
      .query({
        query: getCollectionsQuery,
        fetchPolicy: 'no-cache',
        variables: { 
          channel: 'default-channel',
          cursor: null,
          perPage: 100,
          filter: { metadata: [{ key: "home", value: "true" }] },
        },
      })
      .then(({ data }) => data),
    apolloClient
      .query({
        query: getFeaturedProductsQuery,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => data),
  ]);

  return {
    revalidate: incrementalStaticRegenerationRevalidate,
    props: { data: { 
      ...data, 
      featuredProducts: featuredProducts?.collections?.edges ?  featuredProducts.collections.edges : [], 
      collections,
    } },
  };
};
