import classNames from "classnames";
import Link from "next/link";
import Image from "next/image";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { paths } from "@paths";
import { FeaturedProducts } from "@utils/ssr";

import { ProductsFeatured, Carousel, Promotions } from "../../components";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import {
  HomePageProducts_categories,
  HomePageProducts_shop,
} from "./gqlTypes/HomePageProducts";

import "./scss/index.module.scss";
import { isMobile } from "react-device-detect";
import arrowDownImg from "../../images/arrow-down.svg"
import ReactSVG from "react-svg";

const Page: React.FC<{
  categories: HomePageProducts_categories;
  featuredProducts: FeaturedProducts;
  shop: HomePageProducts_shop;
  collections: any;
}> = ({ categories, featuredProducts, shop, collections }) => {

  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };

  const getPath = (category) => {
    let provided_url = category.metadata.find( meta => meta.key === 'url')
    if (provided_url) {
      return provided_url.value
    }

    return generatePath(paths.category, {
      slug: category.slug,
    })
  }

  const getFeaturedProductsTitle = (collection) => {
    let titleMeta = collection.metadata.find(item => item.key === 'title')
    return titleMeta?.value || ''
  }

  const sortCategories = (a, b) => {
    if (a.node.name === 'Drinkies App') {
      return -1
    }
    return 0
  }


  return (
    <>
      <script defer className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      <Promotions collections={collections}/>
      {isMobile ?
      <div className="arrow-down-img">
        <ReactSVG path={arrowDownImg} />
      </div>
      : <></>
      }
      <div className="intro">
        <h1><FormattedMessage id="Order drinks online" defaultMessage="Order drinks online" /></h1>
        <h3><FormattedMessage id="Get your favorite beers, wines, spirits, draught & more delivered to your door." defaultMessage="Get your favorite beers, wines, spirits, draught & more delivered to your door." /></h3>
      </div>
      <ProductsFeatured
        products={featuredProducts[0]?.node.products?.edges?.map( item => item.node)}
        title={getFeaturedProductsTitle(featuredProducts[0]?.node)}
      />
      {categoriesExist() && (
        <div className="home-page__categories">
          <div className="container">
            <h3>
              <FormattedMessage id="Categories" defaultMessage="Categories" />
            </h3>
            <Carousel dragging={false}>
              {categories.edges
                .sort(sortCategories)
                .filter(({ node: category }) => category.name?.toLowerCase() !== 'rewards')
                .map(({ node: category }) => (
                <div key={category.id} className="category-item">
                  <Link
                    href={getPath(category)}
                    key={category.id}
                  >
                    <a>
                      <Image 
                        loader={() => category.backgroundImage ? category.backgroundImage.url : '/no-photo.webp'}
                        className={classNames(
                          "home-page__categories__list__image rounded-16",
                          {
                            "home-page__categories__list__image--no-photo": !category.backgroundImage,
                          },
                        )}
                        src={
                          category.backgroundImage ? category.backgroundImage.url : '/no-photo.webp'
                        }
                        width='380px'
                        height='500px'
                        alt="Categories"
                      />
                      <h3>{category.name}</h3>
                    </a>
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
      {featuredProducts[1] &&
        <ProductsFeatured
          products={featuredProducts[1]?.node.products?.edges?.map( item => item.node)}
          title={getFeaturedProductsTitle(featuredProducts[1]?.node)}
          link={`/collection/${featuredProducts[1].node.slug}`}
        />
      }

      {featuredProducts[2] &&
        <ProductsFeatured
          products={featuredProducts[2]?.node.products?.edges?.map( item => item.node)}
          title={getFeaturedProductsTitle(featuredProducts[2]?.node)}
          link={`/collection/${featuredProducts[2]?.node.slug}`}
        />
      }
    </>
  );
};

export default Page;
